import angular from "angular";
import $ from 'jquery';
import 'bootstrap';

angular
    .module('ui-sentinel.sentry')
    .controller('SentryAdminController', SentryAdminController);

/////////////

SentryAdminController.$inject = ['$rootScope', '$state', 'SentryAdminApiService', 'SentryAccountApiService', 'SentinelUiSession', 'AccountApiService'];

function SentryAdminController($rootScope, $state, SentryAdminApiService, SentryAccountApiService, SentinelUiSession, AccountApiService) {
    const pageViews = [
        {
            name: 'assign',
            title: 'Assign',
            icon: 'fa-plus'
        },
        {
            name: 'list',
            title: 'Availability',
            icon: 'fa-list'
        },
        {
            name: 'search',
            title: 'Search',
            icon: 'fa-binoculars'
        },
    ];

    const vm = {
        accounts: null,
        assignAccount: null,
        assignImeiText: null,
        assignIsBackfill: false,
        assignBackfillFrom: null,
        list: null,
        isOrs: $rootScope.isOrs,
        isOrsAccountObserver: $rootScope.isOrs && SentinelUiSession.user.isAccountObserver,
        orsHideEditName: $rootScope.isOrs && (!SentinelUiSession.user.isAnAdmin || !SentinelUiSession.user.accountIsTopParent),
        hasPermission: {
            toSetDeviceName: false,
            toRemove: false,
            toSetLogRetriever: false,
            toConfig: false,
            toSeeCommandLogs: false,
        },
        filter: 'all',
        logRetrieverFilter: 'all',
        searchText: null,
        searchResults: null,
        page: 1,
        totalPages: 1,
        totalItems: 0,
        pageArray: null,
        itemsPerPage: 500,
        pageViewTitle: '',
        changeView: changeView,
        currentPageView: {
            name: 'list',
            title: 'Availability',
            icon: 'fa-list'
        },
        pageViews: pageViews,
        search: search,
        load: load,
        assignSubmit: assignSubmit,
        assignCancel: assignCancel,
        removeAssignment: null,
        removeInProgress: false,
        removeBegin: removeBegin,
        removeCancel: removeCancel,
        removeSubmit: removeSubmit,
        friendlyNameSentry: null,
        sentrySelected: null,
        friendlyNameErrorMessage: null,
        logRetrieverBegin: logRetrieverBegin,
        logRetrieverCancel: logRetrieverCancel,
        logRetrieverSubmit: logRetrieverSubmit,
        logRetriever: {
            value: 'no',
            isPristine: true,
            hasError: function () {
                return this.errors.isBlank;
            },
            errors: {
                isBlank: true,
            },
        },
        setFriendlyNameBegin: setFriendlyNameBegin,
        setFriendlyNameCancel: setFriendlyNameCancel,
        setFriendlyNameSubmit: setFriendlyNameSubmit,
        getConfig: getConfig,
        next: next,
        previous: previous,
        gotoPage: gotoPage,
        errorMessage: null,
        assignErrorMessage: null,
        removeErrorMessage: null,
        isSystemAdmin: false,
        getCommandQueue: getCommandQueue,
        getCommandLog: getCommandLog,
    };

    const genericErrorMessage = "Unexpected error occurred in the sentry admin panel";
    const genericAssignErrorMessage = "Unexpected error occurred while assigning the sentry to an account";
    const genericRemoveErrorMessage = "Unexpected error occurred while removing the sentry from assignment";
    const genericLogRetrieverErrorMessage = "Unexpected error occurred while setting a log retriever";
    activate();
    return vm;

    function activate() {
        load();
        loadAccounts();
        setPermissions();
    }

    function changeView(viewName) {
        if (viewName === 'assign') {
            assignCancel();
            $('#assignModal').modal('show');
        } else {
            const view = _.find(vm.pageViews, function (v) {
                return v.name === viewName;
            });
            if (view !== undefined) {
                vm.currentPageView = view;
            }
        }
    }

    function load() {
        $rootScope.loading = true;
        $('.modal').modal('hide');

        vm.page = 1;
        vm.list = null;
        vm.errorMessage = null;
        vm.assignErrorMessage = null;
        vm.removeErrorMessage = null;
        vm.isSystemAdmin = SentinelUiSession.user.isSystemAdmin;

        const pagePromise = SentinelUiSession.user.isAccountEditor || SentinelUiSession.user.isAccountObserver
            ? SentryAccountApiService.getLatestAssignmentsCount(vm.filter, vm.logRetrieverFilter).$promise
            : SentryAdminApiService.getLatestAssignmentsCount(SentinelUiSession.focus, vm.filter, vm.logRetrieverFilter).$promise;

        pagePromise.then(
            function (result) {
                vm.totalPages = result.pageCount;
                vm.totalItems = result.itemCount;

                const pageArray = [];
                for (let i = 1; i <= vm.totalPages; i++) {
                    pageArray.push(i);
                }
                vm.pageArray = pageArray;
            },
            function (error) {
                console.log(error);
                vm.errorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericErrorMessage;
            }
        );

        const listPromise = SentinelUiSession.user.isAccountEditor || SentinelUiSession.user.isAccountObserver
            ? SentryAccountApiService.getLatestAssignments(vm.filter, vm.page, vm.logRetrieverFilter).$promise
            : SentryAdminApiService.getLatestAssignments(SentinelUiSession.focus, vm.filter, vm.page, vm.logRetrieverFilter).$promise;

        listPromise.then(
            function (result) {
                vm.list = result;
            },
            function (error) {
                console.log(error);
                vm.errorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function setPermissions() {
        vm.hasPermission.toSetDeviceName = true;
        vm.hasPermission.toRemove = SentinelUiSession.user.isSystemAdmin;
        vm.hasPermission.toSetLogRetriever = SentinelUiSession.user.isSystemAdmin;
        vm.hasPermission.toConfig = SentinelUiSession.user.isSystemAdmin ||
            SentinelUiSession.user.isAccountAdmin ||
            SentinelUiSession.user.isAccountEditor;
        vm.hasPermission.toSeeCommandLogs = SentinelUiSession.user.isSystemAdmin;
    }

    function loadAccounts() {
        vm.errorMessage = null;
        const listPromise = AccountApiService.listAccounts().$promise;
        listPromise.then(
            function (result) {
                vm.accounts = result;
            },
            function (error) {
                console.log(error);
                vm.errorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericErrorMessage;
            }
        );
    }

    function search() {
        vm.list = null;
        vm.errorMessage = null;
        $rootScope.loading = true;

        const listPromise = SentryAdminApiService.searchLatestAssignments(SentinelUiSession.focus, vm.searchText).$promise;

        listPromise.then(
            function (result) {
                vm.list = result;
                vm.totalPages = 1;
                vm.totalItems = result.length;
            },
            function (error) {
                console.log(error);
                vm.errorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function assignCancel() {
        vm.assignErrorMessage = null;
        vm.assignAccount = null;
        vm.assignImeiText = null;
        vm.assignBackfillFrom = null;
    }

    function assignSubmit() {
        if (!vm.assignAccount || !vm.assignImeiText) {
            return;
        }

        $rootScope.loading = true;
        vm.assignErrorMessage = null;
        const imeiList = _.split(vm.assignImeiText, '\n');

        if (vm.assignIsBackfill) {
            const backfillPromise = SentryAdminApiService.backfillSentries(vm.assignAccount, imeiList, vm.assignBackfillFrom).$promise;
            backfillPromise.then(
                function (result) {
                    load();
                    assignCancel();
                    changeView('list');
                },
                function (error) {
                    console.log(error);
                    vm.assignErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericAssignErrorMessage;
                }
            ).finally(function () {
                $rootScope.loading = false;
            });
            return;
        }

        const assignPromise = SentryAdminApiService.assignSentries(vm.assignAccount, imeiList).$promise;
        assignPromise.then(
            function (result) {
                load();
                assignCancel();
                changeView('list');
            },
            function (error) {
                console.log(error);
                vm.assignErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericAssignErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function removeBegin(assignment) {
        vm.removeInProgress = true;
        vm.removeAssignment = assignment;
        vm.removeErrorMessage = null;
    }

    function removeCancel() {
        vm.removeErrorMessage = null;
        vm.removeInProgress = false;
        vm.removeAssignment = null;
    }

    function removeSubmit() {
        if (!vm.removeAssignment || !vm.removeAssignment.latestAssignment) {
            return;
        }
        $rootScope.loading = true;
        vm.removeErrorMessage = null;

        const promise = SentryAdminApiService.removeSentry(vm.removeAssignment.latestAssignment.accountId, vm.removeAssignment.imei).$promise;
        promise.then(
            function (result) {
                load();
                removeCancel();
            },
            function (error) {
                console.log(error);
                vm.removeErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericRemoveErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function logRetrieverBegin(sentry) {
        vm.logRetrieverErrorMessage = null;
        vm.logRetrieverInProgress = true;
        vm.logRetrieverSentry = sentry;
        vm.logRetriever.value = typeof sentry.logRetriever !== "undefined" && sentry.logRetriever ? 'yes' : 'no';
        vm.logRetriever.isPristine = false;
        vm.logRetriever.errors.isBlank = false;
    }

    function logRetrieverCancel() {
        vm.logRetrieverErrorMessage = null;
        vm.logRetrieverInProgress = false;
        vm.logRetrieverSentry = null;
    }

    function logRetrieverSubmit() {
        if (!vm.logRetriever || !vm.logRetriever.value) {
            vm.logRetrieverErrorMessage = "Please fill all the required fields";
            vm.logRetriever.errors.isBlank = typeof vm.logRetriever === 'undefined' || vm.logRetriever.value === null;
            return;
        }

        const logRetrieverValue = vm.logRetriever.value === "yes" ? true : false;

        $rootScope.loading = true;
        vm.logRetrieverErrorMessage = null;

        const promise = SentryAdminApiService.putLogRetriever(vm.logRetrieverSentry.imei, logRetrieverValue).$promise;

        promise.then(
            function (result) {
                //load();
                vm.logRetrieverSentry.logRetriever = logRetrieverValue;
                vm.logRetrieverErrorMessage = null;
                vm.logRetrieverInProgress = false;
                $("#logRetrieverModal").modal('hide');
            },
            function (error) {
                console.log(error);
                vm.logRetrieverErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericLogRetrieverErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function setFriendlyNameBegin(sentry) {
        vm.friendlyNameErrorMessage = null;
        vm.sentrySelected = sentry;
        vm.friendlyNameSentry = angular.copy(sentry);
    }

    function setFriendlyNameCancel() {
        vm.friendlyNameErrorMessage = null;
        vm.friendlyNameSentry = false;
    }

    function setFriendlyNameSubmit() {
        $rootScope.loading = true;

        const updatePromise = SentinelUiSession.user.isSystemAdmin ?
            SentryAdminApiService.updateSentry(vm.friendlyNameSentry.imei, vm.friendlyNameSentry.friendlyName).$promise :
            SentryAccountApiService.updateSentry(vm.friendlyNameSentry.imei, vm.friendlyNameSentry.friendlyName).$promise;

        updatePromise.then(
            function (result) {
                //load();
                $("#friendlyNameModal").modal('toggle');
                vm.sentrySelected.friendlyName = vm.friendlyNameSentry.friendlyName;
                setFriendlyNameCancel();
            },
            function (error) {
                console.log(error);
                vm.friendlyNameErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericRemoveErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function getConfig(item) {
        $state.go('sentry-configs.by-device', {
            assignmentAccountId: item.latestAssignment.accountId,
            imei: item.imei
        });
    }

    function getCommandQueue(item) {
        $state.go('sentry-commands.queue', { imei: item.imei });
    }

    function getCommandLog(item) {
        $state.go('sentry-commands.log', { imei: item.imei });
    }

    function next() {
        if (vm.page !== vm.totalPages) {
            gotoPage(vm.page + 1);
        }
    }

    function previous() {
        if (vm.page !== 1) {
            gotoPage(vm.page - 1);
        }
    }

    function gotoPage(page) {
        $rootScope.loading = true;
        if (page < 1 || page > vm.totalPages) {
            return;
        }
        vm.list = null;
        vm.page = page;
        vm.errorMessage = null;

        const listPromise = SentinelUiSession.user.isAccountEditor || SentinelUiSession.user.isAccountObserver
            ? SentryAccountApiService.getLatestAssignments(vm.filter, vm.page, vm.logRetrieverFilter).$promise
            : SentryAdminApiService.getLatestAssignments(SentinelUiSession.focus, vm.filter, vm.page, vm.logRetrieverFilter).$promise;

        listPromise.then(
            function (result) {
                vm.list = result;
            },
            function (error) {
                console.log(error);
                vm.list = [];
                vm.errorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }
}
