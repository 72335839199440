import angular from "angular";

angular
    .module('api-sentinel')
    .constant('SENTINEL_API_HOST_CONSTANTS', {
        'URL': 'https://ors-api.onasset.com',
        'VISION_URL': 'https://tracking.organ-recovery.com/track/',
        'ERRORS': {
            'AUTHENTICATION_REQUIRED': 'AUTHENTICATION_REQUIRED'
        },
        'USER_ROLES': {
            none: 'api-none',
            all: 'api-all',
            apiAdmin: 'api-admin',
            apiAccount: 'api-account'
        }
    });
