import angular from "angular";

angular
    .module('ui-sentinel.devices')
    .config(routes);

routes.$inject = ['$stateProvider', 'SENTINEL_API_HOST_CONSTANTS'];

function routes($stateProvider, USER_ROLES) {
    $stateProvider
        .state('devices', {
            abstract: true,
            url: '/devices',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                authorizedRoles: [
                    USER_ROLES.systemAdmin,
                    USER_ROLES.supportAdmin,
                    USER_ROLES.supportObserver,
                    USER_ROLES.accountAdmin,
                    USER_ROLES.accountEditor
                ],
                pageTitle: 'Manage Devices',
                subTitle: null,
                parentState: null
            }
        })
        /*.state('devices.list', {
            url: '/list',
            template: require('./devices-list.html'),
        })*/
        .state('devices.map', {
            url: '/map',
            template: require('./latest-device-tracking/latest-device-tracking-map.html'),
            data: {
                pageTitle: 'Track Devices',
                subTitle: null,
                parentState: null
            },
            params: {
                forceUseLastDateRange: false
            }
        })
        .state('devices.reports', {
            url: '/reports',
            template: require('./latest-device-tracking/latest-device-tracking-list.html'),
            data: {
                pageTitle: 'Track Devices',
                subTitle: null,
                parentState: null
            }
        })
        .state('devices.devicereport', {
            url: '/devicereport',
            template: require('./device-pivot/device-pivot.html'),
            data: {
                pageTitle: 'Device Report',
                subTitle: null,
                parentState: null
            }
        })
        .state('devices.sentinelreport', {
            url: '/sentinelreport',
            template: require('./device-pivot/sentinel-pivot.html'),
            data: {
                pageTitle: 'Sentinel Report',
                subTitle: null,
                parentState: null
            }
        })
        .state('device', {
            abstract: true,
            url: '/devices/:deviceTagId',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                authorizedRoles: [
                    USER_ROLES.systemAdmin,
                    USER_ROLES.supportAdmin,
                    USER_ROLES.supportObserver,
                    USER_ROLES.accountAdmin,
                    USER_ROLES.accountEditor
                ],
                pageTitle: 'Manage Devices',
                subTitle: null,
                parentState: 'devices.list'
            },
            params: {
                referrer: 'devices.list',
                referrerParams: null,
                deviceName: null
            }
        })
        /*.state('device.admin', {
            url: '/admin',
            template: require('./device-admin.html')
        })*/
        .state('device.reports', {
            url: '/reports',
            template: require('./device-tracking/device-tracking-list.html'),
            data: {
                pageTitle: 'Track Devices',
                subTitle: null,
                parentState: 'devices.map'
            }
        })
        .state('device.map', {
            url: '/map',
            template: require('./device-tracking/device-tracking-map.html'),
            data: {
                pageTitle: 'Track Devices',
                subTitle: null,
                parentState: 'devices.map'
            }
        })
        .state('device.summary', {
            url: '/summary',
            template: require('./device-tracking/device-tracking-summary.html'),
            data: {
                pageTitle: 'Track Devices',
                subTitle: null,
                parentState: 'devices.map'
            }
        })
        .state('device.sentinelreports', {
            url: '/sentinelreports',
            template: require('./sentinel-tracking/sentinel-tracking-list.html'),
            data: {
                pageTitle: 'Track Devices',
                subTitle: null,
                parentState: 'devices.map'
            }
        })
        .state('device.sentinelmap', {
            url: '/sentinelmap',
            template: require('./sentinel-tracking/sentinel-tracking-map.html'),
            data: {
                pageTitle: 'Track Devices',
                subTitle: null,
                parentState: 'sentinel.map'
            }
        })
        .state('device.sentinelsummary', {
            url: '/sentinelsummary',
            template: require('./sentinel-tracking/sentinel-tracking-summary.html'),
            data: {
                pageTitle: 'Track Devices',
                subTitle: null,
                parentState: 'sentinel.map'
            }
        });
}
