import angular from "angular";
import $ from 'jquery';

angular
    .module('ui-sentinel.devices.latestDeviceTracking')
    .controller('LatestDeviceTrackingMapController', LatestDeviceTrackingMapController);


LatestDeviceTrackingMapController.$inject = ['$rootScope', '$scope', '$filter', '$state', 'SentinelUiSession', 'localStorageService', 'LatestDeviceTrackingReportsService', 'TrackingFilterService', 'MapsConstants', 'UomSecondsConverter'];

function LatestDeviceTrackingMapController($rootScope, $scope, $filter, $state, SentinelUiSession, localStorageService, LatestDeviceTrackingReportsService, TrackingFilterService, MapsConstants, UomSecondsConverter) {
    const googleMapDivId = 'latestDeviceTrackingMap';

    const markerZIndices = {
        'ok-network': 10,
        'ok-gps': 11,
        'info-network': 12,
        'info-gps': 13,
        'warning-network': 14,
        'warning-gps': 15,
        'selected': 17,
        'ok-anchor': 16
    };

    const vm = {
        map: null,
        mapType: MapsConstants.mapTypes.hybrid,
        mapTypes: MapsConstants.mapTypes,
        mapZooms: MapsConstants.zooms,
        filterService: TrackingFilterService,
        reportsService: LatestDeviceTrackingReportsService,
        sessionService: SentinelUiSession,
        secondsService: UomSecondsConverter,
        zoomLimit: 10,
        zoomLevel: null,
        center: null,
        reportMarkers: [],
        selectedReportMarker: null,
        selectedIndex: null,
        hover: null,
        nextReport: null,
        previousReport: null,
        onReportsChange: onReportsChange,
        onSelectedReportChange: onSelectedReportChange,
        onFilterChange: onFilterChange,
        isOrs: $rootScope.isOrs,
        accountIsTopParent: SentinelUiSession.user.accountIsTopParent,
        isAnAdmin: SentinelUiSession.user.isAnAdmin,
        actions: {
            map: {
                setZoom: setZoom,
                setType: setType
            },
            reportMarker: {
                panTo: panToReportMarker,
                centerOnTimeline: centerOnTimeline
            },
            selectReport: selectReport,
            closeReportDetails: closeReportDetails,
            goToDeviceMap: goToDeviceMap,
            goToSentinelMap: goToSentinelMap,
            goToDeviceAdmin: goToDeviceAdmin,
            goToDeviceReports: goToDeviceReports,
            gotoSightingsForReport: gotoSightingsForReport,
            goToDeviceAlarms: goToDeviceAlarms,
        }
    };

    activate();
    return vm;

    ////////////////////////////////////////////////////////////////////////////


    function activate() {
        console.log("$rootScope.$state.params.forceUseLastDateRange", $rootScope.$state.params.forceUseLastDateRange);
        vm.reportsService.restartCache($rootScope.$state.params.forceUseLastDateRange);
        vm.reportsService.init($rootScope.$state.params.forceUseLastDateRange);

        $rootScope.$on('CLIENT_FOCUS_CHANGED', function (event, args) {
            if ($rootScope.$state.current.name == 'devices.map') {
                vm.reportsService.load(vm.reportsService.fromDate, vm.reportsService.toDate);
            }
        });

        initMap();

        $scope.$watchCollection(
            function () {
                return vm.reportsService.reports;
            },
            function (reports) {
                onReportsChange(reports);
            }
        );
        $scope.$watch(
            function () {
                return vm.reportsService.selected;
            },
            function (report) {
                onSelectedReportChange(report);
            }, true
        );
        $scope.$watch(
            function () {
                return vm.filterService;
            },
            function () {
                onFilterChange();
            }, true
        );
    }

    function addReportMarkerListeners(marker) {
        google.maps.event.addListener(marker, 'click', function () {
            onReportMarkerClick(marker);
            $scope.$apply();
        });
    }

    function centerOnTimeline() {
        if (vm.reportsService.selected) {
            vm.reportsService.selected.centerOnTimeline();
        }
        $('#btn-details-center-timeline').blur();
    }

    function clearMarkers() {
        _.forEach(vm.reportMarkers, function (marker) {
            marker.setMap(null);
        });
        vm.reportMarkers = [];
    }

    function closeReportDetails() {
        vm.reportsService.selected = null;
    }

    function goToDeviceMap() {
        if (vm.reportsService.selected) {
            $state.go('device.map', { deviceTagId: vm.reportsService.selected.deviceTagId, deviceName: vm.reportsService.selected.friendlyName || vm.reportsService.selected.deviceTagId, referrer: 'devices.map' });
        }
    }

    function goToSentinelMap() {
        if (vm.reportsService.selected) {
            $state.go('device.sentinelmap', {
                deviceTagId: vm.reportsService.selected.deviceTagId,
                referrer: 'devices.map'
            });
        }
    }

    function goToDeviceReports() {
        let from = null;
        if (vm.reportsService.selected && vm.reportsService.selected.type === "Sentry") {
            if (vm.isOrs) {
                $state.go('device.reports', { deviceTagId: vm.reportsService.selected.deviceTagId, referrer: 'devices.map' });
            } else {
                from = moment(vm.reportsService.selected.timeOfReceipt).subtract(1, 'days').toISOString();
                const to = moment().toISOString();
                $state.go('sentry-reports.by-device', {
                    imei: vm.reportsService.selected.deviceTagId,
                    view: 'prior',
                    to: to,
                    from: from
                });
            }
        } else if (vm.reportsService.selected && vm.reportsService.selected.type === "Sentinel") {
            from = moment(vm.reportsService.selected.timeOfReceipt).subtract(1, 'days').toISOString();
            $state.go('sightings.of-mac', {
                mac: vm.reportsService.selected.deviceTagId,
                to: vm.reportsService.selected.timeOfReceipt,
                from: from
            });
        }
    }

    function gotoSightingsForReport() {
        if (vm.reportsService.selected && vm.reportsService.selected.type === "Sentry") {
            $state.go('sightings.for-report', {
                reportId: vm.reportsService.selected.reportGuid,
                reportsList: vm.reportsService.reports
            });
        }
    }

    function goToDeviceAdmin() {
        if (vm.reportsService.selected && vm.reportsService.selected.type === "Sentry") {
            $state.go('sentry-configs.by-device', {
                assignmentAccountId: vm.reportsService.selected.accountId,
                imei: vm.reportsService.selected.deviceTagId
            });
        }
    }

    function goToDeviceAlarms() {
        if (vm.reportsService.selected && vm.reportsService.selected.type === "Sentry") {
            $state.go('alarms.by-device', {
                assignmentAccountId: vm.reportsService.selected.accountId,
                device: vm.reportsService.selected.deviceTagId
            });
        } else {
            $state.go('alarms.by-sentinel', {
                assignmentAccountId: vm.reportsService.selected.accountId,
                sentinel: vm.reportsService.selected.deviceTagId
            });
        }
    }

    function handleIdle() {
        vm.center = {
            lat: Math.round(vm.map.getCenter().lat() * 1000000) / 1000000,
            lng: Math.round(vm.map.getCenter().lng() * 1000000) / 1000000
        };
        vm.zoomLevel = vm.map.getZoom();

        localStorageService.set('mapCenter', vm.center);
        localStorageService.set('mapZoomLastDeviceTracking', vm.zoomLevel);
        $scope.$apply();
    }

    function initMap() {
        if (vm.reportMarkers.length) {
            var mapCenter = localStorageService.get('mapCenter');
            const mapCenterLat = localStorageService.get('mapCenterLat');
            const mapCenterLng = localStorageService.get('mapCenterLng');
            var mapZoom = localStorageService.get('mapZoomLastDeviceTracking');
        }

        vm.map = new google.maps.Map(document.getElementById(googleMapDivId), {
            zoom: mapZoom || MapsConstants.zooms.world.zoomLevel,
            minZoom: 2,
            center: mapCenter || MapsConstants.zooms.world.center,
            mapTypeId: vm.mapType.type,
            mapTypeControl: true,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.HYBRID, google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.TERRAIN]
            }
        });

        vm.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('legendTool'));
        vm.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('mapPropTool'));
        onWindowResize();

        google.maps.event.addListener(vm.map, 'idle', handleIdle);
        google.maps.event.addListener(vm.map, 'click', onMapClick);
        google.maps.event.addDomListener(window, 'resize', onWindowResize);
    }

    function onFilterChange() {
        _.forEach(vm.reportMarkers, function (marker) {
            marker.setVisible(vm.filterService.filter(marker.report));
        });

        if (vm.selectedReportMarker && !vm.filterService.filter(vm.selectedReportMarker.report)) {
            selectReport(null);
        }
    }

    function onReportMarkerClick(marker) {
        vm.reportsService.selected = vm.reportsService.selected === marker.report ? null : marker.report;
    }

    function onReportsChange(reports) {
        clearMarkers();

        const bounds = new google.maps.LatLngBounds();

        _.forEach(reports, function (report) {
            if (report.latitude !== null && report.longitude !== null) {
                const strLocationMethod = $filter('locationMethod')(report.locationMethod, report.latitude, report.longitude);
                const zIndexName = report.severity.toLowerCase() + '-' + strLocationMethod.toLowerCase();
                const marker = angular.extend(
                    new google.maps.Marker({
                        id: report.reportGuid,
                        icon: MapsConstants.icons.deviceReports.normal.default,
                        zIndex: markerZIndices[zIndexName],
                        position: {
                            lat: report.latitude,
                            lng: report.longitude
                        },
                        map: vm.map,
                        visible: vm.filterService.filter(report)
                    }),
                    {
                        report: report
                    }
                );
                setReportMarkerIcon(marker);
                addReportMarkerListeners(marker);
                vm.reportMarkers.push(marker);

                if (report.latitude !== 0 || report.longitude !== 0) {
                    const latlng = new google.maps.LatLng(report.latitude, report.longitude);
                    bounds.extend(latlng);
                }
            }
        });

        if (reports.length == 1) {
            // set center of map
            vm.map.setCenter(bounds.getCenter());
            vm.map.setZoom(16);
        } else if (reports.length > 1) {
            // fit to bounds
            vm.map.fitBounds(bounds);
        }
    }

    function onSelectedReportChange(report) {
        if (vm.selectedReportMarker) {
            vm.selectedReportMarker.setMap(null);
            vm.selectedReportMarker = null;
            vm.selectedIndex = null;
        }

        if (!report) {
            return;
        }

        const index = _.findIndex(vm.reportsService.reports, function (r) {
            return r.guid === report.guid;
        });

        vm.selectedIndex = index;
        vm.nextReport = index === 0 ? null : findNextVisible(index);
        vm.previousReport = index === vm.reportsService.reports.length - 1 ? null : findPrevVisible(index);

        if (!report.latitude || !report.longitude) {
            return;
        }

        const strLocationMethod = $filter('locationMethod')(report.locationMethod, report.latitude, report.longitude);
        let iconName = '../img/' + report.severity + '-' + strLocationMethod.toLowerCase() + '-selected.png';
        if (report.beaconType == "Sentinel 100A" || report.beaconType == "Mobile SDK Anchor")
            iconName = '../img/' + report.severity + '-anchor-selected.png';
        vm.selectedReportMarker = new google.maps.Marker({
            id: 'selected',
            icon: {
                url: iconName,
                anchor: { x: 11, y: 11 },
                scaledSize: { height: 24, width: 24 }
            },
            zIndex: markerZIndices.selected,
            position: {
                lat: report.latitude,
                lng: report.longitude
            },
            map: vm.map
        });
        addReportMarkerListeners(vm.selectedReportMarker);
        vm.selectedReportMarker.report = report;
    }

    function findNextVisible(selectedIndex) {
        for (let i = selectedIndex - 1; i >= 0; i--) {
            if (vm.filterService.filter(vm.reportsService.reports[i])) {
                return vm.reportsService.reports[i];
            }
        }
        return null;
    }

    function findPrevVisible(selectedIndex) {
        for (let i = selectedIndex + 1; i <= vm.reportsService.reports.length - 1; i++) {
            if (vm.filterService.filter(vm.reportsService.reports[i])) {
                return vm.reportsService.reports[i];
            }
        }
        return null;
    }

    function onMapClick() {
        if (vm.reportsService.selected) {
            vm.reportsService.selected = null;
            $scope.$apply();
        }
    }

    function onWindowResize() {
        if ($state.current.name !== 'devices.map') {
            return;
        }

        const center = vm.map.getCenter();

        const newHeight = window.innerHeight * 0.60;
        const mapDiv = document.getElementById(googleMapDivId);
        mapDiv.style.height = Math.round(newHeight) + 'px';

        const mapItemListDiv = document.getElementById('map-items-list');
        mapItemListDiv.style.height = (Math.round(newHeight) + 172) + 'px';

        google.maps.event.trigger(vm.map, 'resize');
        vm.map.setCenter(center);
    }

    function panToReportMarker(marker) {
        vm.map.panTo(marker.getPosition());
        vm.map.setZoom(11);
        $('#btn-details-center-map').blur();
    }

    function setReportMarkerIcon(marker) {
        if (!marker || !marker.report) {
            return;
        }
        const strLocationMethod = $filter('locationMethod')(marker.report.locationMethod, marker.report.latitude, marker.report.longitude);
        let iconName = '../img/' + marker.report.severity + '-' + strLocationMethod.toLowerCase() + '.png';
        if (marker.report.beaconType == "Sentinel 100A" || marker.report.beaconType == "Mobile SDK Anchor") {
            iconName = '../img/' + marker.report.severity + '-anchor.png';
        }
        const iconOptions = {
            url: iconName,
            anchor: { x: 11, y: 11 },
            scaledSize: { height: 24, width: 24 }
        };
        marker.setIcon(iconOptions);
    }

    function setType(mapType) {
        vm.mapType = mapType;
        vm.map.setMapTypeId(mapType.type);
    }

    function setZoom(zoom) {
        google.maps.event.trigger(vm.map, 'resize');
        vm.map.panTo(zoom.center);
        vm.map.setZoom(zoom.zoomLevel);
    }

    function selectReport(report) {
        console.log("selectReport", report);
        vm.reportsService.selected = vm.reportsService.selected === report ? null : report;
        $('#btn-details-select-prev').blur();
        $('#btn-details-select-next').blur();
    }
}
